import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/packages/paste-website/src/layouts/DefaultLayout.tsx";
import { graphql } from 'gatsby';
import { Callout, CalloutTitle, CalloutText } from '../../components/callout';
import { Anchor } from '@twilio-paste/anchor';
export const pageQuery = graphql`
  {
    mdx(fields: {slug: {eq: "/content/"}}) {
      fileAbsolutePath
      frontmatter {
        slug
        title
      }
      headings {
        depth
        value
      }
    }
  }
`;
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageAside = makeShortcode("PageAside");
const layoutProps = {
  pageQuery,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <content>
      <h1>{props.pageContext.frontmatter.title}</h1>
      <p>{props.pageContext.frontmatter.description}</p>
    </content>
    <hr></hr>
    <contentwrapper>
      <PageAside data={props.data.mdx} mdxType="PageAside" />
      <content>
        <Callout variant="warning" mdxType="Callout">
  <CalloutTitle as="h4" mdxType="CalloutTitle">Early Access! Early Access! Early Access!</CalloutTitle>
  <CalloutText mdxType="CalloutText">
    These guidelines are still in development and are not yet complete. If you can't see what you need here, you've
    found an edge-case or exception, or you have other comments, reach out to Caroline Tecks on Slack.
  </CalloutText>
        </Callout>
        <p><strong parentName="p">{`Use the guidance in the way that makes most sense to you.`}</strong>{`
These content pages should help you to feel more confident in writing concise, clear, and consistent product content.`}</p>
        <p>{`You might read `}<a parentName="p" {...{
            "href": "/content/voice-and-tone"
          }}>{`Voice and tone`}</a>{` once for foundational understanding, while you might bookmark `}<a parentName="p" {...{
            "href": "/content/product-style-guide"
          }}>{`Product style guide`}</a>{` for ease of reference.`}</p>
        <p>{`Look out for training sessions coming soon to help you make the most of these pages.`}</p>
        <h2>{`Sections at a glance `}{`—`}{` find what you need`}</h2>
        <h3>{`Voice and tone`}</h3>
        <ul>
          <li parentName="ul">{`Understand voice and tone and their role in communicating.`}</li>
          <li parentName="ul">{`Identify and use the Twilio Voice consistently.`}</li>
          <li parentName="ul">{`Use tone to shape the user experience.`}</li>
          <li parentName="ul">{`Find the appropriate tone and approach.`}</li>
          <li parentName="ul">{`Go to `}<a parentName="li" {...{
              "href": "/content/voice-and-tone"
            }}>{`Voice and tone`}</a></li>
        </ul>
        <h3>{`Product style guide`}</h3>
        <ul>
          <li parentName="ul">{`The core principles of our content and "how we should write."`}</li>
          <li parentName="ul">{`Punctuation guidance and exceptions.`}</li>
          <li parentName="ul">{`Formatting and grammar tips, including capitals, lists, bold/italics, numbers, acronyms, and abbreviations.`}</li>
          <li parentName="ul">{`Commonly confused or misspelled words and phrases.`}</li>
          <li parentName="ul">{`Go to `}<a parentName="li" {...{
              "href": "/content/product-style-guide"
            }}>{`Product style guide`}</a></li>
        </ul>
        <h3>{`Components (coming in Q2)`}</h3>
        <ul>
          <li parentName="ul">{`Applying guidance to specific components. Covering buttons, headings, modals, navigation etc.`}</li>
        </ul>
        <h3>{`Get support`}</h3>
        <ul>
          <li parentName="ul">{`Check and edit content yourself with prompts and guidance.`}</li>
          <li parentName="ul">{`Submit a request for content support from a UX Writer.`}</li>
          <li parentName="ul">{`Go to `}<a parentName="li" {...{
              "href": "/content/get-support"
            }}>{`Get Support`}</a></li>
        </ul>
        <h2>{`Feedback`}</h2>
        <p>{`These guidelines rely on your feedback to evolve and grow. Please reach out on Slack with`}</p>
        <ul>
          <li parentName="ul">{`Usability feedback `}{`—`}{` the more specific, the better.`}</li>
          <li parentName="ul">{`Edge cases or exceptions `}{`—`}{` send over screen shots and queries.`}</li>
          <li parentName="ul">{`Additional examples `}{`—`}{` have a great example of before/after?`}</li>
          <li parentName="ul">{`Questions and queries `}{`—`}{` if something isn't clear, let us know.`}</li>
        </ul>
        <Callout mdxType="Callout">
  <CalloutTitle as="h4" mdxType="CalloutTitle">Need support with non-product content?</CalloutTitle>
  <CalloutText mdxType="CalloutText">
    {' '}
    Visit the <Anchor href="https://www.twilio.com/brand/guidelines/writing-style" mdxType="Anchor">
      Brand Writing Style Guidelines here
    </Anchor>.
  </CalloutText>
        </Callout>
      </content>
    </contentwrapper>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      